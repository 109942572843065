@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
.navbar {
  background: rgba(242, 242, 242, 0.70);
backdrop-filter: blur(20px);
border-bottom: 1px solid rgba(25, 25, 25, 0.10);
}
.df-c-bg{
  /* background: linear-gradient(2deg, #FC466B 0%, #3F5EFB 100%);
opacity: 0.30000001192092896; */
/* background-image: url("/public/assets/images/bk_header.svg");
background-repeat: no-repeat; */
  /* background: linear-gradient(3deg,#ffffff00 0%, hsla(348, 97%, 63%, 0.3) 40%, hsla(230, 96%, 62%, 0.3) 100%); */
/* height: 253px; */

/* margin-top: 88px; */
display: flex;
  align-items: center;
  justify-content: center;

}
h1{
  font-size: 69px;
}
h6{
  font-size: 36px;
  font-weight: 600;
}
footer{
  /* height: 429px; */
}
.btn-rounded{
  border-radius: 50px;
  
}
button{
  background-color: "#000000" !important;
}

a {
  color: #109279;
  text-decoration: none;
}
.dot {
  font-size: 19px;
  font-weight: bold;
  text-align: center;
}
.custom-button {
  background-image: linear-gradient(90deg, #0796C4 0%, #01C673 100%);
  color: #fff; /* Text color (white in this case) */
  padding: 8px 16px; /* Adjust the padding as needed */
  border-radius: 8px; /* Adjust the border radius as needed */
}

.custom-button:hover {
  /* Custom styles for button on hover */
  opacity: 0.8; /* For example, reduce opacity on hover */
}
.bg-footer {
  margin-top: 10px;
  background: linear-gradient(90deg, #3884CC 0%, #00D09C 100%)
}

.MuiTimelineDot-root {
  background-color: #01C448;
  width: 16px;
  height: 16px;
  position: relative;
}
.MuiTimelineDot-root::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white; /* Color of the hole */
  border-radius: 50%;
}
.MuiAccordionDetails-root .MuiTimeline-root .MuiTimelineItem-root:last-child .MuiTimelineConnector-root {
  display: none;
}



